import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const Dispositivo = () => import('@/entities/dispositivo/dispositivo.vue');
const DispositivoUpdate = () => import('@/entities/dispositivo/dispositivo-update.vue');
const DispositivoDetails = () => import('@/entities/dispositivo/dispositivo-details.vue');

const Partita = () => import('@/entities/partita/partita.vue');
const PartitaUpdate = () => import('@/entities/partita/partita-update.vue');
const PartitaDetails = () => import('@/entities/partita/partita-details.vue');

const Capo = () => import('@/entities/capo/capo.vue');
const CapoUpdate = () => import('@/entities/capo/capo-update.vue');
const CapoDetails = () => import('@/entities/capo/capo-details.vue');

const Prodotto = () => import('@/entities/prodotto/prodotto.vue');
const ProdottoUpdate = () => import('@/entities/prodotto/prodotto-update.vue');
const ProdottoDetails = () => import('@/entities/prodotto/prodotto-details.vue');

const Cliente = () => import('@/entities/cliente/cliente.vue');
const ClienteUpdate = () => import('@/entities/cliente/cliente-update.vue');
const ClienteDetails = () => import('@/entities/cliente/cliente-details.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'dispositivo',
      name: 'Dispositivo',
      component: Dispositivo,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    {
      path: 'dispositivo/new',
      name: 'DispositivoCreate',
      component: DispositivoUpdate,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    {
      path: 'dispositivo/:dispositivoId/edit',
      name: 'DispositivoEdit',
      component: DispositivoUpdate,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    {
      path: 'dispositivo/:dispositivoId/view',
      name: 'DispositivoView',
      component: DispositivoDetails,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    {
      path: 'partita',
      name: 'Partita',
      component: Partita,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    {
      path: 'partita/new',
      name: 'PartitaCreate',
      component: PartitaUpdate,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    {
      path: 'partita/:partitaId/edit',
      name: 'PartitaEdit',
      component: PartitaUpdate,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    {
      path: 'partita/:partitaId/view',
      name: 'PartitaView',
      component: PartitaDetails,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    {
      path: 'capo',
      name: 'Capo',
      component: Capo,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    {
      path: 'capo/new',
      name: 'CapoCreate',
      component: CapoUpdate,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    {
      path: 'capo/:capoId/edit',
      name: 'CapoEdit',
      component: CapoUpdate,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    {
      path: 'capo/:capoId/view',
      name: 'CapoView',
      component: CapoDetails,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    {
      path: 'prodotto',
      name: 'Prodotto',
      component: Prodotto,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    {
      path: 'prodotto/new',
      name: 'ProdottoCreate',
      component: ProdottoUpdate,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    {
      path: 'prodotto/:prodottoId/edit',
      name: 'ProdottoEdit',
      component: ProdottoUpdate,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    {
      path: 'prodotto/:prodottoId/view',
      name: 'ProdottoView',
      component: ProdottoDetails,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    {
      path: 'cliente',
      name: 'Cliente',
      component: Cliente,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    {
      path: 'cliente/new',
      name: 'ClienteCreate',
      component: ClienteUpdate,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    {
      path: 'cliente/:clienteId/edit',
      name: 'ClienteEdit',
      component: ClienteUpdate,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    {
      path: 'cliente/:clienteId/view',
      name: 'ClienteView',
      component: ClienteDetails,
      meta: { authorities: [Authority.USER, Authority.ADMIN] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
